<script>
import Layout from "../layouts/main";
import PageHeader from "@/components/admin/header/page-header";
import EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import axios from 'axios';

export default {
    page: {
        title: "Email Note",
        meta: [
            {
                name: "description",
                content: 'description',
            },
        ],
    },

    data() {
        return {
            url: process.env.VUE_APP_URL,
            isshow: false,
            isShowData: false,
            submitted: false,
            title: "Email Note",
            searchValueTable: "",
            headers: [
                { text: "Full Name", value: "fullName" },
                { text: "note", value: "note" },
                // { text: "Type", value: "type" },
                // { text: "Action", value: "editData" }
            ],
            tableItems: [],
            tableData: [],
            editData: '',

        }
    },
    components: {
        Layout,
        PageHeader,
        EasyDataTable,
    },
    mounted() {
        this.getEmailNote()
    },
    activated() {
        this.getEmailNote()
    },
    methods: {
        getEmailNote() {
            axios.post(this.url + "api/getEmailNote").then((res) => {
                this.tableItems = res.data;
                console.log(res.data)
            });
        },
  
    }
};
</script>
<template>
    <layout>
        <div class="row mb-3 align-items-center">
            <div class="col">
                <PageHeader :title="title" />
            </div>
            <div class="col-auto">
                <!-- <a href="javascript:void(0)" class="btn btn-theme"><i class="bx bx-user-plus font-size-18 align-middle me-2"></i>Add Client</a> -->
                <!-- <button type="button" @click="modalTaskTotalAdd = true,resetFields()" class="btn btn-theme">
                    <i class="bx bx-user-plus font-size-18 align-middle me-2"></i>
                    Add Service
                </button> -->
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="main-search-card ">
                    <EasyDataTable :headers="headers" :items="tableItems" :search-value="searchValueTable" border-cell
                        theme-color="#3aafa9" :rows-per-page="25" buttons-pagination
                        table-class-name="table-custom-style">
                        <template #item-fullName="{ user }">
                            {{ user.firstName }}
                        </template>
                    </EasyDataTable>
                </div>
            </div>
        </div>
    </layout>
</template>
<style scoped>
.fade-enter-active {
    transition: all 0.3s ease-out;
}

.fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter-from,
.fade-leave-to {
    transform: translateY(-20px);
    opacity: 0;
}
</style>